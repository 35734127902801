import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useRef } from "react";
import { getFleetListUser, getLocationListByType } from "../API";
import { robotmsgAction } from "../redux/Actions";
import { useNavigate } from "react-router-dom";
import { FiNavigation2 } from "react-icons/fi";

function MapboxRobot(props) {
  const [userControllingMap, setUserControllingMap] = useState(false);
  const locationsMarkerData = useRef({
    showLocations: false,
    locations: [],
    locationMarkers: [],
  });

  //icon for showing marker location
  const locationOn = `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="100%" width="100%" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z"></path></svg>`;

  //icon for hiding marker location
  const locationOff = `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="100%" width="100%" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0zm11.75 11.47l-.11-.11z"></path><path d="M12 6.5A2.5 2.5 0 0114.5 9c0 .74-.33 1.39-.83 1.85l3.63 3.63c.98-1.86 1.7-3.8 1.7-5.48 0-3.87-3.13-7-7-7a7 7 0 00-5.04 2.15l3.19 3.19c.46-.52 1.11-.84 1.85-.84zm4.37 9.6l-4.63-4.63-.11-.11L3.27 3 2 4.27l3.18 3.18C5.07 7.95 5 8.47 5 9c0 5.25 7 13 7 13s1.67-1.85 3.38-4.35L18.73 21 20 19.73l-3.63-3.63z"></path></svg>`;

  //icon for showing pickup locations
  const locationPickup = `<svg stroke="currentColor" fill="yellow" stroke-width="0" viewBox="0 0 24 24" height="100%" width="100%" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z"></path></svg>`;

  //icon for showing drop locations
  const locationDrop = `<svg stroke="currentColor" fill="green" stroke-width="0" viewBox="0 0 24 24" height="100%" width="100%" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z"></path></svg>`;

  //fetching location list
  useEffect(() => {
    getLocationListByType(props.fleetData._id).then((res) => {
      locationsMarkerData.current = {
        ...locationsMarkerData.current,
        locations: res.data.data,
      };
    });
  }, []);

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const robotDetails = useSelector((state) => state.robotDetailsReducer);
  mapboxgl.accessToken =
    "pk.eyJ1IjoibXJsYWtzaHlhZ3VwdGEiLCJhIjoiY2xpZWZlcTJjMGo4ZDNkczBwc2ZmcW1qMyJ9.Kurk7BS04invJcCwS0m4Pg";

  const { robotDirections } = useSelector(
    (state) => state.robotDirectionsReducer
  );
  const robotlinecolor = [
    "#ffffff",
    "#3246B2",
    "#53B6CC",
    "#E68D40",
    "#74CB6B",
    "#D04622",
    "#292F40",
    "#F9ED4F",
  ];

  const { robotmsgData } = useSelector((state) => state.robotMessageReducer);
  const { fleetList } = useSelector((state) => state.fleetList);

  const [destinationMarker, setDestinationMarker] = useState(null);
  const [nogozonescoordinates, setnogozonescoordinates] = useState(
    props?.fleetData?.map?.nogoZones?.map((data) => [data.lng, data.lat])
  );
  const [geofencecoordinates, setgeofencecoordinates] = useState(
    props?.fleetData?.map?.geofence?.map((data) => [data.lng, data.lat])
  );

  const mapRef = useRef(null);

  const [robotMarker, setRobotMarker] = useState(null);
  const [polygonarray, setPolygonarray] = useState([]);

  const [robotMarkerArray, setRobotMarkerArray] = useState([]);
  const [destinationMarkerArray, setDestinationMarkerArray] = useState([]);

  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(15);
  const isLatitude = (num) => isFinite(num) && Math.abs(num) <= 90;

  const isLongitude = (num) => isFinite(num) && Math.abs(num) <= 180;
  const getrunningstatuscolor = (robotRunningState) => {
    switch (robotRunningState) {
      case "AUTO_SYSTEM_FAULT":
        return "Dashboard_page_individual_robot_robotstatus_div AutoSystem_fault_error_p";
      case "ERROR":
        return "Dashboard_page_individual_robot_robotstatus_div AutoSystem_fault_error_p";
      case "AUTO_OBSTACLE":
        return "Dashboard_page_individual_robot_robotstatus_div AutoObstacle_teleop_obstacle_p";
      case "AUTO_RUN_OBSTACLE":
        return "Dashboard_page_individual_robot_robotstatus_div Teleop_autoRun_p";
      case "AUTO_RUN_MOVING":
        return "Dashboard_page_individual_robot_robotstatus_div AutoRun_moving_p";
      case "AUTO_RUN":
        return "Dashboard_page_individual_robot_robotstatus_div Teleop_autoRun_p";
      case "TELE_OP_OBSTACLE":
        return "Dashboard_page_individual_robot_robotstatus_div AutoObstacle_teleop_obstacle_p";
      case "TELE_OP_MOVING":
        return "Dashboard_page_individual_robot_robotstatus_div teleopMoving_bordertop_p";
      case "TELE_OP":
        return "Dashboard_page_individual_robot_robotstatus_div Teleop_autoRun_p";
      case "PAYLOAD_STATE":
        return "Dashboard_page_individual_robot_robotstatus_div payloadState_bordertop_p";
      case "MANUAL":
        return "Dashboard_page_individual_robot_robotstatus_div Teleop_autoRun_p";
      default:
        return "Dashboard_page_individual_robot_robotstatus_div defaultBorderTop_P";
    }
  };

  const getrobotStatus = (status) => {
    switch (status) {
      case "UNAVAILABLE":
        return '<p class="color-red Dashboard_page_robotStatusClass">  UNAVAILABLE </p>';
      case "AVAILABLE":
        return '<p class="color-green Dashboard_page_robotStatusClass"> AVAILABLE  </p>';
      case "ERROR":
        return '<p class="color-yellow Dashboard_page_robotStatusClass"> Error</p>';
      default:
        return '<p class="color-red Dashboard_page_robotStatusClass"> {status}</p>';
    }
  };

  const getrobotIcons = (item) => {
    const batteryIcon = `
    <div class="battery-icon-css">
        <span style="width: 30px; border: 1px solid #fff; border-radius: 4px; padding: 2px; height: 14px; display: inline-block;">
            <strong style="background: ${
              parseFloat(item.robotBattery).toFixed(1) <= 20
                ? "#E4261C"
                : "rgb(113, 207, 98)"
            }; width: ${parseFloat(item.robotBattery).toFixed(
      1
    )}%; display: block; height: 8px;"></strong>
        </span>
    </div>
    <p style="font-size: 10px; text-align: center; font-weight: bold;">${parseFloat(
      item.robotBattery
    ).toFixed(0)}%</p>
`;

    const signalDivWrapper = `
    <div class="Signal_div_wrapper">
        <span style="background: #fff; width: 3px; height: 3px; display: inline-block; border-radius: 3px;"></span>
        <span style="background: #fff; width: 3px; height: 6px; display: inline-block; border-radius: 3px;"></span>
        <span style="background: #fff; width: 3px; height: 9px; display: inline-block; border-radius: 3px;"></span>
        <span style="background: #fff; width: 3px; height: 12px; display: inline-block; border-radius: 3px; opacity: 0.5;"></span>
        <span style="background: #fff; width: 3px; height: 15px; display: inline-block; border-radius: 3px; opacity: 0.5;"></span>
    </div>
`;

    const robotBoxContent = `
        ${batteryIcon}
        ${signalDivWrapper}
`;
    return robotBoxContent;
  };

  class PitchToggle {
    constructor({ bearing = -20, pitch = 70, minpitchzoom = null }) {
      this._bearing = bearing;
      this._pitch = pitch;
      this._minpitchzoom = minpitchzoom;
    }

    onAdd(map) {
      this._map = map;
      var tempMap = this._map;
      let _this = this;

      this._btn = document.createElement("button");
      this._btn.className = "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-3d";
      this._btn.type = "button";
      this._btn.style = "padding: 6px";
      this._btn["aria-label"] = "Toggle Pitch";

      this.icon = document.createElement("i");
      this.icon.id = "location-icon-btn";
      this.icon.innerHTML = locationsMarkerData.current.showLocations
        ? locationOn
        : locationOff;

      this._btn.appendChild(this.icon);
      this._btn.onclick = function () {
        let icon = document.getElementById("location-icon-btn");

        //to hide locations markers
        if (locationsMarkerData.current.showLocations) {
          //setting location off icon
          icon.innerHTML = locationOff;

          //removing markers from map
          locationsMarkerData.current.locationMarkers.map((item) =>
            item.remove()
          );

          // removing markers from array
          locationsMarkerData.current.locationMarkers = [];
        }
        //to show locations markers
        else {
          //empty array to store markers
          const markers = [];

          //location on icon
          icon.innerHTML = locationOn;

          //filtering dropoff locations
          const dropoff = locationsMarkerData.current.locations.filter(
            (item) => item._id === "DROPOFF"
          );
          //filtering pickup locations

          const pickup = locationsMarkerData.current.locations.filter(
            (item) => item._id === "PICKUP"
          );

          //rendering pickup locations
          pickup[0].locations.map((item) => {
            //creating marker for a location

            const robotMarkerDiv = document.createElement("div");
            robotMarkerDiv.style.width = "35px";
            robotMarkerDiv.style.height = "35px";
            robotMarkerDiv.innerHTML = locationPickup;

            robotMarkerDiv.style.borderRadius = "100%";
            robotMarkerDiv.style.animation = "example 1s ease-in-out infinite";
            robotMarkerDiv.style.transform = `rotate(${item.headingAngle}deg)`;

            const newMarker = new mapboxgl.Marker(robotMarkerDiv)
              .setLngLat([item.longitude, item.latitude])
              .addTo(tempMap);
            markers.push(newMarker);
          });

          //rendering dropoff locations
          dropoff[0].locations.map((item) => {
            //creating marker for a location
            const robotMarkerDiv = document.createElement("div");
            robotMarkerDiv.style.width = "35px";
            robotMarkerDiv.style.height = "35px";
            robotMarkerDiv.innerHTML = locationDrop;

            robotMarkerDiv.style.borderRadius = "100%";
            robotMarkerDiv.style.animation = "example 1s ease-in-out infinite";
            robotMarkerDiv.style.transform = `rotate(${item.headingAngle}deg)`;

            const newMarker = new mapboxgl.Marker(robotMarkerDiv)
              .setLngLat([item.longitude, item.latitude])
              .addTo(tempMap);

            markers.push(newMarker);
          });

          //adding markers to ref
          locationsMarkerData.current.locationMarkers = [...markers];
        }

        //changing state for showing locations
        locationsMarkerData.current.showLocations =
          !locationsMarkerData.current.showLocations;
      };

      this._container = document.createElement("div");
      this._container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
      this._container.appendChild(this._btn);

      return this._container;
    }

    onRemove() {
      this._container.parentNode.removeChild(this._container);
      this._map = undefined;
    }
  }

  useEffect(() => {
    let tempRobotArray = [];
    let tempDestinationArray = [];
    const initializeMap = () => {
      const newMap = new mapboxgl.Map({
        container: "map-container-id",
        style: "mapbox://styles/mrlakshyagupta/climr57of00le01pgc0jjgfoh",
        center: [
          props.fleetData?.map?.longitude
            ? props.fleetData?.map?.longitude
            : fleetList[0]?.map?.longitude,
          props.fleetData?.map?.latitude
            ? props.fleetData?.map?.latitude
            : fleetList[0]?.map?.latitude,
        ],
        zoom: zoom,
      });
      newMap.addControl(
        new mapboxgl.NavigationControl({
          showCompass: false,
          showZoom: true,
        })
      );

      newMap.addControl(new PitchToggle({ minpitchzoom: 11 }), "top-right");

      newMap &&
        newMap.on("wheel", () => {
          setUserControllingMap(true);
        });

      newMap &&
        newMap.on("drag", () => {
          setUserControllingMap(true);
        });

      fleetList &&
        fleetList.length > 0 &&
        fleetList?.map((item) => {
          if (item?.fleetId === props?.fleetData?.fleetId) {
            item?.robots?.map((robot, index) => {
              const el = document.createElement("div");
              el.className = `marker-${index + 1}`;
              let newMarker = new mapboxgl.Marker(el)
                .setLngLat([robot.longitude, robot.latitude])
                .setPopup(
                  new mapboxgl.Popup()
                    .setHTML(
                      '<div class="Dasboard_page_individual_robot_box_wrapper">' +
                        '  <div class="d-flex-center Dasboard_page_individual_robot_box_1">' +
                        "    <p>OTTOBOT</p>" +
                        '    <div class="' +
                        getrunningstatuscolor(robot.robotRunningState) +
                        '">' +
                        '      <span class="state_border">' +
                        robot.robotRunningState +
                        "</span>" +
                        "    </div>" +
                        "  </div>" +
                        '  <div class="d-flex-center Dasboard_page_individual_robot_box_2">' +
                        "    <div>" +
                        '      <h4 class="Dasboard_page_individual_robot_h4">' +
                        robot.robotId +
                        "</h4>" +
                        "      " +
                        getrobotStatus(robot.robotStatus) +
                        "    </div>" +
                        "    <div>" +
                        '      <div class="Dashboard_page_robot_div_icons">' +
                        getrobotIcons(robot) +
                        "</div>" +
                        "      <div>" +
                        "        " +
                        (robot.robotStatus === "AVAILABLE"
                          ? '<button class="Pause_btn">Pause</button>'
                          : '<button class="Play_btn">Play</button>') +
                        "" +
                        "      </div>" +
                        "    </div>" +
                        "  </div>" +
                        ' <div class="d-flex-center Dasboard_page_individual_robot_box_1">' +
                        "<div>  <p>Coordinates </p>" +
                        '<p class="Coordinates_p">' +
                        robot.latitude +
                        "," +
                        robot.longitude +
                        "</p>" +
                        "</div>" +
                        "<div> <p>Status </p>" +
                        "<p class=" +
                        (robot.connectionStatus === "OFFLINE"
                          ? "p-color-red-two"
                          : "p-color-green-two") +
                        ">" +
                        robot.connectionStatus +
                        "</p>" +
                        "</div>" +
                        "</div>" +
                        "</div>"
                    )
                    .on("open", function () {
                      const divElement = document.querySelector(
                        ".Dasboard_page_individual_robot_box_wrapper"
                      );
                      if (divElement) {
                        divElement.addEventListener("click", function () {
                          let latitude;
                          let longitude;
                          fleetList.map((ttt) => {
                            if (ttt.fleetId === robot.fleetId) {
                              ttt.robots.map((itmmm) => {
                                if (itmmm.robotId === robot.robotId) {
                                  latitude = itmmm.latitude;
                                  longitude = itmmm.longitude;
                                }
                                return null;
                              });
                            }
                            return null;
                          });
                          navigate(`/robotPage/${robot.robotId}`, {
                            state: {
                              data: robot,
                              latitude: latitude,
                              longitude: longitude,
                              robotlistData: props.fleetData?.robots,
                              marker: `marker-${index + 1}`,
                            },
                          });
                        });
                      }
                    })
                )
                .setRotation(
                  parseFloat(
                    robot.headingSlam
                      ? robot.headingSlam
                      : robot.heading
                      ? robot.heading
                      : 0
                  )
                )
                .setRotationAlignment("map")
                .addTo(newMap);
              newMarker.id = `robot-${index + 1}`;
              tempRobotArray.push(newMarker);
              return null;
            });
          }
          return null;
        });

      fleetList &&
        fleetList.length > 0 &&
        fleetList?.map((item) => {
          if (item?.fleetId === props?.fleetData?.fleetId) {
            item?.robots?.map((robot, index) => {
              const el = document.createElement("div");
              el.className = `destinationMarker-${index + 1}`;
              let newMarker = new mapboxgl.Marker(el)
                .setLngLat([0, 0])
                .addTo(newMap);
              newMarker.id = `destination-${index + 1}`;
              tempDestinationArray.push(newMarker);

              return null;
            });
          }
          return null;
        });
      newMap.on("move", () => {
        setZoom(newMap.getZoom().toFixed(2));
      });

      setRobotMarkerArray([...tempRobotArray]);
      setDestinationMarkerArray([...tempDestinationArray]);
      setMap(newMap);

      mapRef.current = newMap;
      newMap &&
        nogozonescoordinates &&
        nogozonescoordinates.length > 0 &&
        newMap.on("load", () => {
          newMap.addSource("nogozone", {
            type: "geojson",
            data: {
              type: "Feature",
              geometry: {
                type: "Polygon",
                // These coordinates outline Maine.
                coordinates: [nogozonescoordinates],
              },
            },
          });
          newMap.addLayer({
            id: "nogozoneoutline",
            type: "line",
            source: "nogozone",
            layout: {},
            paint: {
              "line-color": "#f00",
              "line-width": 3,
            },
          });
        });

      newMap &&
        geofencecoordinates &&
        geofencecoordinates.length > 0 &&
        newMap.on("load", () => {
          newMap.addSource("geofence", {
            type: "geojson",
            data: {
              type: "Feature",
              geometry: {
                type: "Polygon",
                coordinates: [geofencecoordinates],
              },
            },
          });
          newMap.addLayer({
            id: "geofenceoutline",
            type: "line",
            source: "geofence",
            layout: {},
            paint: {
              "line-color": "#f00",
              "line-width": 3,
            },
          });
        });

      let newpolygonarray = [];
      props.fleetData.map.fleetzones &&
        props.fleetData.map.fleetzones.length > 0 &&
        props.fleetData.map.fleetzones?.map((polygon, index) => {
          newpolygonarray.push(polygon?.map((data) => [data.lng, data.lat]));
          newMap &&
            newMap.on("load", () => {
              newMap.addSource("fleetzone-" + index, {
                type: "geojson",
                data: {
                  type: "Feature",
                  geometry: {
                    type: "Polygon",
                    coordinates: [newpolygonarray[index]],
                  },
                },
              });
              newMap.addLayer({
                id: "fleetzoneoutline-" + index,
                type: "fill",
                source: "fleetzone-" + index,
                layout: {},
                paint: {
                  "fill-color": "#7859bc",
                  "fill-opacity": 1,
                },
              });
            });

          return newpolygonarray;
        });

      newMap &&
        newMap.on("move", () => {
          setZoom(newMap.getZoom().toFixed(2));
        });

      setPolygonarray(newpolygonarray);
    };

    fleetList.forEach((item, key) => {
      item?.robotsDetails?.forEach((robot, index) => {
        map &&
          map.on("load", () => {
            if (
              item?.robots.find((robo) => robo.robotId === robot.robotId)
                .robotCommandList?.length
            ) {
              const sourceId = `robotpath-${index}`;
              const layerId = `robotpathlayer-${index}`;

              map.addSource(sourceId, {
                type: "geojson",
                data: {
                  type: "Feature",
                  geometry: {
                    type: "LineString",
                    coordinates: [
                      robot.path?.map((data) => [data.lng, data.lat]),
                    ],
                  },
                },
              });

              map.addLayer({
                id: layerId,
                type: "line",
                source: sourceId,
                layout: {},
                paint: {
                  "line-color":
                    robotlinecolor[
                      fleetList[0]?.robots?.findIndex(
                        (robotc) => robotc.robotId === robot.robotId
                      ) + 1
                    ],
                  "line-width": 5,
                },
              });
            }
          });
      });
    });

    if (!map) {
      initializeMap();
    }
  }, [map, setMap, fleetList]);

  const checkActionId = (commandListData, props) => {
    if (
      !localStorage.getItem("actionId") ||
      localStorage.getItem("actionId") === "null"
    ) {
      localStorage.setItem(
        "actionId",
        JSON.stringify({
          actionType: commandListData.actionType,
          actionId: commandListData.actionId,
        })
      );
      return true;
    } else {
      if (
        commandListData.actionId !==
        JSON.parse(localStorage.getItem("actionId")).actionId
      ) {
        localStorage.setItem(
          "actionId",
          JSON.stringify({
            actionType: commandListData.actionType,
            actionId: commandListData.actionId,
          })
        );
        return true;
      } else {
        return false;
      }
    }
  };

  const updateRobotpath = () => {
    if (map?.isStyleLoaded()) {
      fleetList[0]?.robotsDetails?.forEach((robot, index) => {
        if (robot?.hasOwnProperty("path") && robot?.path?.length > 0) {
          const newrobotPath = robot.path.map((pathDirection) => [
            pathDirection.lng,
            pathDirection.lat,
          ]);
          const mySource = map.getSource(`robotpath-${index}`);
          map &&
            mySource &&
            mySource.setData({
              type: "Feature",
              properties: {},
              geometry: {
                type: "LineString",
                coordinates: newrobotPath,
              },
            });
          map.getLayer("path") &&
            map.setPaintProperty(
              "path",
              "line-color",
              `${
                robotlinecolor[
                  fleetList[0]?.robots?.findIndex(
                    (robotc) => robotc.robotId === robot.robotId
                  ) + 1
                ]
              }`
            );
        }
      });
    }
  };

  useEffect(() => {
    if (
      (robotmsgData &&
        robotmsgData.length > 0 &&
        !robotmsgData.find((robotData) => robotData.location === "zaragoza")) ||
      !robotmsgData
    ) {
      getFleetListUser(
        localStorage.getItem("useremail"),
        localStorage.getItem("token")
      ).then((res) => {
        dispatch(robotmsgAction(res.data.fleet));
      });
    }

    if (robotMarkerArray.length > 0) {
      fleetList?.map((item) => {
        item?.robots?.map((robot, index) => {
          robotMarkerArray[index]
            ?.setLngLat([robot.longitudeSlam, robot.latitudeSlam])
            .setRotation(
              parseFloat(
                robot.headingSlam
                  ? robot.headingSlam
                  : robot.heading
                  ? robot.heading
                  : 0
              )
            )
            .setRotationAlignment("map");
          return null;
        });
        return null;
      });
    }
    if (destinationMarkerArray.length > 0) {
      fleetList?.map((item) => {
        item?.robots?.map((robot, index) => {
          if (robot?.robotCommandList?.length) {
            let activeAction = robot?.robotCommandList?.find(
              (action) => action.actionPriority === 1
            );
            if (
              activeAction &&
              isLongitude(activeAction.longitude) &&
              isLatitude(activeAction.latitude)
            ) {
              destinationMarkerArray[index]?.setLngLat([
                activeAction.longitude,
                activeAction.latitude,
              ]);
            } else {
              destinationMarkerArray[index]?.setLngLat([0, 0]);
            }
          }
          return null;
        });
        return null;
      });
    }

    if (props?.fleetData?.map?.geofencezone?.length > 0) {
      setgeofencecoordinates(
        props?.geofencezone?.map((data) => [data.lng, data.lat])
      );
    }

    if (props.fleetzones?.length > 0) {
      let newpolygonarray = [];
      props?.fleetzones?.map((polygon, index) => {
        newpolygonarray.push(polygon.map((data) => [data.lng, data.lat]));
        return newpolygonarray;
      });
      setPolygonarray(newpolygonarray);
    }

    updateRobotpath();

    if (!userControllingMap) {
      if (geofencecoordinates.length > 0) {
        const bounds = new mapboxgl.LngLatBounds(
          geofencecoordinates[0],
          geofencecoordinates[0]
        );

        for (const coord of geofencecoordinates) {
          bounds.extend(coord);
        }

        map?.fitBounds(bounds, {
          padding: 20,
        });
      }
    }
  }, [
    robotMarker,
    robotMarkerArray,
    fleetList,
    destinationMarker,
    props.robotId,
    props.robotpathcheck,
    dispatch,
    robotDirections,
    robotDetails,
  ]);

  return (
    <div className="h-full">
      <div
        className={`w-full min-h-[35px] h-max text-white p-[6px_12px] z-[1] absolute top-0 left-0 m-3 rounded-[4px]`}
      >
        {userControllingMap && (
          <div
            className={`cursor-pointer flex items-center gap-2 sidebar bg-white text-[#00B7D4] p-[6px_12px] z-[1] absolute bottom-0 left-0 m-1 rounded-[4px] h-[30px]`}
            onClick={() => setUserControllingMap(false)}
          >
            <FiNavigation2 strokeWidth={3} />
            <span className="text-xs font-bold uppercase">Re-centre</span>
          </div>
        )}
      </div>
      <div
        id="map-container-id"
        className="map-container"
        style={{ height: "100%" }}
      ></div>
    </div>
  );
}

export default MapboxRobot;
